import(/* webpackMode: "eager" */ "/vercel/path0/apps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["LoadingIndicatorView"] */ "/vercel/path0/apps/web/components/progress.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/ScriptSnippets/DelightScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/apps/web/components/ScriptSnippets/TripleWhale.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApolloWrapper"] */ "/vercel/path0/apps/web/data/apollo/ApolloWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/vercel/path0/apps/web/data/auth/NextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextImage"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextImage.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicNextLink"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicNextLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["PrismicPreviewClient"] */ "/vercel/path0/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"components/fonts.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../../packages/ui/src/fonts/Mohr-Regular.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-RegularIt.woff2\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-SemiBold.woff2\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-SemiBoldIt.woff2\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-Bold.woff2\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-BoldIt.woff2\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-Black.woff2\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-BlackIt.woff2\",\"weight\":\"800\",\"style\":\"italic\"},{\"path\":\"../../../packages/ui/src/fonts/Mohr-Heavy.woff2\",\"weight\":\"900\",\"style\":\"normal\"}],\"variable\":\"--font-mohr\"}],\"variableName\":\"mohrFont\"}");
