"use client";

import Script from "next/script";

export default function DelightScript() {
  const onLoad = () => {
    window.delightHostPartnerId = "6644813c6321e7af1c4cbe67";
    window.delightApiToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Ik1lbWJlcnNAY3JhZnRnaW5jbHViLmNvLnVrIiwiaWQiOiI2NjQ0ODEzYzYzMjFlN2FmMWM0Y2JlNjciLCJpYXQiOjE3MTU3NjU1NjQsImV4cCI6MjAzMTM0MTU2NH0.tcHJQXI3tDAXniN9TUir2hgmAxSaUcWve1QEUN0NsCE";
  };

  return (
    <>
      <Script
        id="delight-plugin-js"
        src="https://delight-custom-plugins.fly.dev/web/js/delight-plugin.js"
        onLoad={onLoad}
      />
    </>
  );
}
